<template>
  <div class="modal-content">
    <div class="box">
      <div class="columns is-mobile">
        <div class="column">
          <p class="subtitle has-text-dark">Update date</p>
        </div>
        <div class="column is-narrow">
          <a class="has-text-danger" @click="$modal.close">Close</a>
        </div>
      </div>
      <form class="container is-narrow" @submit.prevent="submit">
        <div class="field">
          <p class="subtitle is-6">
            {{ availableDates }}
          </p>
          <div class="columns is-multiline">
            <div class="column is-12">
              <DatePicker
                v-model="date"
                :inline="true"
                format="YYYY-MM-DD"
                :min-date="dateRange.min"
                :max-date="dateRange.max"
                color="#DF4E3C"
                button-color="#DF4E3C"
                :only-date="true"
                :no-header="false"
              />
            </div>
          </div>
        </div>
        <div class="columns has-text-right">
          <div class="column">
            <span class="button is-text" @click.prevent="$modal.close">
              Cancel
            </span>
            <button
              type="submit"
              class="button is-info is-rounded"
              :class="{ 'is-loading': $wait.is('loading') }"
            >
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as companion from 'modules/companion/services'
import * as layout from 'modules/layout/services'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  name: 'CompanionDate',
  components: {
    DatePicker: () => import('vue-ctk-date-time-picker')
  },
  data: () => ({ date: '' }),
  mounted() {
    this.date = this.valuationDate
  },
  computed: {
    ...mapGetters('companion', [
      'vrm',
      'valuationMileage',
      'valuationDate',
      'namaGrade '
    ]),
    ...mapGetters('auth', ['hasFutureDatedValuations']),
    dateRange: () => layout.dateRange(),
    availableDates: () => layout.availableDates()
  },
  methods: {
    async submit() {
      try {
        this.$wait.start('loading')
        const { vrm, date, valuationMileage, namaGrade } = this
        const options = { forceEstimate: valuationMileage.isEstimated }

        await companion.value({ vrm, date, namaGrade, options })
      } finally {
        this.$wait.end('loading')
        this.$modal.close()
      }
    }
  }
}
</script>

<style lang="sass">
.vdp-datepicker__calendar
  max-width: 100%
.modal-content
  @media screen and (max-width: 768px)
    .hero
      height: 100vh
      overflow: scroll
.header-picker
  background-color: $date-picker-header-background-color !important
</style>
